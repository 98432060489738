const cardsData = [
  {
    id: 1,
    image: '/assets/SS/General/Star.png',
    title: 'Best Quality',
    description: 'Worldwide Recognition',
    details: 'IEC welcomes Refugees in Canada and will be glad to assist Convention Refugees & Persons need in protection.',
  },
  {
    id: 2,
    image: '/assets/SS/General/Star.png',
    title: 'Affordability',
    description: '1$ CAD=0.75$ USD / 1$ CAD= 0.6 £ UK',
    details: 'IEC can provide all existing & established immigration services in Canada ( Business Immigration, Study & Work permit, Family Sponsorship, Federal & Provincial Immigration, Adoption, etc.)',
  },
  {
    id: 3,
    image: '/assets/SS/General/Star.png',
    title: 'Immigration through Education',
    description: 'welcomes business professionals',
    details: 'Our company welcomes business professionals, who prefer to do business in Canada and Immigrate through LMIA Work permit & Entrepreneur Business Immigration Program.',
  },
  {
    id: 4,
    image: '/assets/SS/General/Star.png',
    title: 'Approximately, it takes 3-5 years',
    description: '2 Official Languages: English and French',
    details: 'Our company is official representative of more than 200 schools in Canada and will be glad to assist with Language courses, Secondary & Post-Secondary Education.',
  },
  
];



export default function SectionBenefits () {
  return (
    <div className="container mx-auto px-2 md:px-32">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
        {cardsData.map((card) => (
          // <div key={card.id} className="border rounded-lg shadow-lg p-4">
          <div key={card.id} className=" p-1 ">
            <img
              src={card.image}
              alt={card.title}
              className="w-full h-52 object-fill rounded-t-lg "
            />
            {/* <h2 className="font-bold text-xl mt-4 text-center">{card.title}</h2> */}
            <p className="text-gray-700 mt-2 text-center text-2xl">{card.title}</p>
            <p className="text-gray-600 mt-2 text-center text-lg">{card.description}</p>
            {/* <p className="text-gray-500 mt-2 text-sm">{card.details}</p> */}
            {/* <button className="text-blue-500 text-right mt-4 float-right">
              More...
            </button> */}
          </div>
        ))}
      </div>
    </div>
  );
};


