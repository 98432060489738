import React, { useRef, useEffect } from "react";

export default function VideoSection() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // videoRef.current.volume = 0.5; // Set volume to 50%
    }
  }, []);

  return (
    // <section className="py-10 bg-gray-50">
    <div className=" mx-auto px-2 md:px-4 ">

      {/* <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">
          Watch Our Introductory Video
        </h2> */}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
        <div className="flex items-center justify-center aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg ">
          <div className="px-10">

            <h2 className="font-bold text-xl mt-4 text-center">{'applications to Immigration'}</h2>
            <p className="text-gray-700 mt-2 text-center text-lg">{'Assistance with applications to Immigration'}</p>
            <p className="text-gray-500 mt-2 text-sm">{'We assist with applications to Immigration & Refugee Board of Canada for next services: Appeals(Sponsorship, Residency Obligation, Removal Orders, Minister’s Appeal), Refugee, Admissibility hearings, Detention reviews.'}</p>
          </div>
        </div>

        {/* <div className="flex items-center justify-center aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg "> */}
        <div className="flex items-center justify-center aspect-w-16 aspect-h-9 ">
          <video
            ref={videoRef}
            //muted
            //autoplay
            loop
            playsInline
            controls
            // className="w-[300px] h-auto "
            className="w-[300px] h-[300px]  rounded-lg overflow-hidden shadow-lg "
            poster="/BizAppsSlim.jpg"
          >
            <source src="/Videos/Sample/Sample.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
    // </section>
  );
};

