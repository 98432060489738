const cardsData = [
  {
    id: 1,
    image: 'https://via.placeholder.com/150',
    title: 'Refugee',
    description: 'welcomes Refugees in Canada',
    details: 'IEC welcomes Refugees in Canada and will be glad to assist Convention Refugees & Persons need in protection.',
  },
  {
    id: 2,
    image: 'https://via.placeholder.com/150',
    title: 'Immigration Services',
    description: 'existing & established immigration services',
    details: 'IEC can provide all existing & established immigration services in Canada ( Business Immigration, Study & Work permit, Family Sponsorship, Federal & Provincial Immigration, Adoption, etc.)',
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/150',
    title: 'Business Immigration',
    description: 'welcomes business professionals',
    details: 'Our company welcomes business professionals, who prefer to do business in Canada and Immigrate through LMIA Work permit & Entrepreneur Business Immigration Program.',
  },
  {
    id: 4,
    image: 'https://via.placeholder.com/150',
    title: 'Study Programs',
    description: 'official representative',
    details: 'Our company is official representative of more than 200 schools in Canada and will be glad to assist with Language courses, Secondary & Post-Secondary Education.',
  },
  {
    id: 5,
    image: 'https://via.placeholder.com/150',
    title: 'IRB',
    description: 'assist with applications',
    details: 'We assist with applications to Immigration & Refugee Board of Canada for next services: Appeals(Sponsorship, Residency Obligation, Removal Orders, Minister’s Appeal), Refugee, Admissibility hearings, Detention reviews.',
  },
  {
    id: 6,
    image: 'https://via.placeholder.com/150',
    title: 'Work Programs',
    description: 'This is a short description for card 6.',
    details: 'Our company assists with work permits to Canada for eligible candidates ( IEC Work permit, LMIA work permit, Spouse Open Work permit, Post-Graduate Work permit, etc.)',
  },
];







 







export default function SectionServices () {
  return (
    <div className="container mx-auto px-2 md:px-20">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {cardsData.map((card) => (
          <div key={card.id} className="border rounded-lg shadow-lg p-4">
            <img
              src={card.image}
              alt={card.title}
              // className="w-full h-40 object-fill rounded-t-lg"
              className="w-40 h-40 mx-auto object-fill rounded-full"
            />
            <h2 className="font-bold text-xl mt-4 text-center">{card.title}</h2>
            <p className="text-gray-700 mt-2 text-center text-lg">{card.description}</p>
            <p className="text-gray-500 mt-2 text-sm">{card.details}</p>
            <button className="text-blue-500 text-right mt-4 float-right">
              More...
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};


