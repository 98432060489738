import React from 'react'
import { AlertRec } from '../StdLib'
import SectionBanner from '../Components/SectionBanner'

export default function Contact() {

  // AlertRec(_AccRecs, 'ABOUT PAGE ******************** NEAT _AccRec in main prgb**********************')
  return (
    <>
     <SectionBanner>
        <div className='absolute bottom-0 right-2 px-2' style={{ lineHeight: '1' }}>
          <div className='font-poppins text-5xl tracking-wider'>Welcome!</div>
        </div>
      </SectionBanner>

      <div >
        <div className='w-full md:flex '>
          <div className='px-2 md:ps-40 w-full md:w-1/2'>
            <p className='text-5xl mt-0 font-poppins'><strong><span className='text-blue-700'>Sahu</span><span className='text-gray-400'> &</span><span className='text-red-700'> Co.</span></strong> Welcomes You</p>

            <div className='mt-4'>
              <p className='text-2xl font-poppins'>Main Office</p>
              <p className='text-base font-poppins px-4'># 12345</p>
              <p className='text-base font-poppins px-4'>Zeroth Floor, Mall o Mall Plaza,</p>
              <p className='text-base font-poppins px-4'>Multan City </p>
              <p className='text-base font-poppins px-4'>Multan </p>
              <p className='text-base font-poppins px-4'>Phone: +92 11 1122334455 </p>
            </div>

            <div className='mt-4'>
              <p className='text-2xl font-poppins'>Front Desk</p>
              <p className='text-base font-poppins px-4'># 12345</p>
              <p className='text-base font-poppins px-4'>Zeroth Floor, Mall o Mall Plaza,</p>
              <p className='text-base font-poppins px-4'>Multan City </p>
              <p className='text-base font-poppins px-4'>Multan </p>
              <p className='text-base font-poppins px-4'>Phone: +92 11 1122334455 </p>
            </div>
          </div>
          <div className='mx-auto w-full md:w-1/2 p-10 '>
            <div className='mx-auto w-full  rounded-xl shadow-xl overflow-hidden'>
              <img className='w-full h-full object-fill' src={'/Images/UnderConstruction/webpage-UC.webp'} alt={''} />
            </div>
          </div>

        </div>
      </div>

    </>
  )
}
