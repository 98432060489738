import React from 'react'
import { AlertRec } from '../StdLib'
import SectionBanner from '../Components/SectionBanner'

export default function About() {

  // AlertRec(_AccRecs, 'ABOUT PAGE ******************** NEAT _AccRec in main prgb**********************')
  return (
    <>
          <SectionBanner>
        <div className='absolute bottom-0 right-2 px-2' style={{ lineHeight: '1' }}>
          <div className='font-poppins text-5xl tracking-wider'>Here We Are...</div>
        </div>
      </SectionBanner>

      <div>
               <p className='text-2xl'>Everything About Us is there</p>

        <div className='mx-auto w-full max-w-4xl h-[400px] rounded-xl shadow-xl overflow-hidden'>
          <img className='w-full h-full object-fill' src={'/Images/UnderConstruction/website-uc.jpg'} alt={''} />
        </div>
      </div>
    </>
  )
}
