import React, { useEffect, useState } from 'react'
// import BizAppLogo from '../Assets/SiteImages/BizAppsSlim.jpg'
import { Parallax } from 'react-parallax';
import Slider from 'react-slick';
import ImageSlider from '../../Components/Slider/Slider';
import AlternatingSections from '../../Components/Sections/Sections';
import SectionServices from './SectionServices';
import SectionBenefits from './SectionBenefits';
import SectionBanner from '../../Components/SectionBanner';
import VideoSection from './VideoSection';

function getCurrentWindowDimensions() {
  if (typeof window !== "undefined") {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }
  else return { width: '', height: '' };
}

const images = [
  '/assets/HeroSec/E1.jpg',
  '/assets/HeroSec/L1.jpg',
  '/assets/HeroSec/L2.jpg',
  '/assets/HeroSec/L3.jpg',
  '/assets/HeroSec/OP1.jpg',
  '/assets/HeroSec/OP2.jpg',
];

const BannerImages = [
  '/assets/SRD/BannerSlider/CheeseCheddar.png',
  '/assets/SRD/BannerSlider/CheeseMozzarela.png',
  '/assets/SRD/BannerSlider/CheesePizza.png',
  '/assets/SRD/BannerSlider/CheeseShredded.png',
  '/assets/SRD/BannerSlider/CheezSlice.jpg',
  '/assets/SRD/BannerSlider/DesiGhee.png',
  '/assets/SRD/BannerSlider/FrenchFries.png',
  '/assets/SRD/BannerSlider/WhiteButter.jpg',
  '/assets/SRD/BannerSlider/WhiteButter.png',
  '/assets/SRD/BannerSlider/Yogurt.png',
  '/assets/SRD/BannerSlider/YogurtPack.png'
]


const Items = [
  '/assets/SRD/Items/Cheddarche240928014018137.png',
  '/assets/SRD/Items/Cheddarche240928014134883.png',
  '/assets/SRD/Items/CheddarPiz240928012137270.png',
  '/assets/SRD/Items/CheddarPiz240928012256448.png',
  '/assets/SRD/Items/CheddarPiz240928014239104.png',
  '/assets/SRD/Items/CheddarShr240928020111149.png',
  '/assets/SRD/Items/CheddarSli240928011050511.png',
  '/assets/SRD/Items/CheddarSli240928011323907.png',
  '/assets/SRD/Items/CheddarSli240928011519403.png',
  '/assets/SRD/Items/CheddarSup240928013824195.png',
  '/assets/SRD/Items/DesiGhee16240928020041862.png',
  '/assets/SRD/Items/DesiGhee1k240928015841166.png',
  '/assets/SRD/Items/DesiGhee50240928015743432.png',
  '/assets/SRD/Items/Mozzeralla240928010554817.png',
  '/assets/SRD/Items/Mozzeralla240928010620530.png',
  '/assets/SRD/Items/Mozzeralla240928010725581.png',
]



// ====================================================================================
export default function Home() {
  //import { useCtxMainContextHook } from '../../CtxMain'
  const [IsMobile, setIsMobile] = useState(false);
  const [contentHeight, setContentHeight] = useState("100vh");
  const [CrntWindow, setCrntWindow] = useState(getCurrentWindowDimensions())

  useEffect(() => {
    // Get the heights of navbar and footer
    const navbarHeight = document.getElementById("TopNavMega1")?.offsetHeight || 0;
    const footerHeight = document.getElementById("Footer1")?.offsetHeight || 0;

    // alert(navbarHeight + footerHeight)
    // Set the remaining height dynamically
    setContentHeight(`calc(100vh - ${navbarHeight + footerHeight}px)`);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // OTHER STEP- Only for CrntWindow Status
      //setCrntWindow(getCurrentWindowDimensions());
      if (typeof window !== "undefined") {
        const { innerWidth: width, innerHeight: height } = window;
        setCrntWindow({ width, height });
      }
      else setCrntWindow({ width: '', height: '' });
      // ---------------------------------



      // OTHER STEP
      // Check if the screen width is at least 'md' size (768px or more)
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Run the check initially
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // <img src={BizAppLogo} alt="BizApps" />

  const MyParallaxComponent = () => (
    <Parallax bgImage="/assets/HeroSec/E1.jpg" strength={500}>
      <div style={{ height: 500 }}>
        <h1>My Parallax Content</h1>
      </div>
    </Parallax>
  );

  return (
    // <main className="flex-grow flex flex-col  items-center "
    //   //  This is Direct calculation
    //   //  style={{ minHeight: 'calc(100vh - 88px)' }}
    //   style={{ minHeight: `${IsMobile ? 'calc(100vh - 100px)' : 'calc(100vh - 72px)'}` }}
    // >
    // <main className="flex-grow flex flex-col justify-center" style={{ minHeight: contentHeight }}>
    <main className="w-full" style={{ minHeight: contentHeight }}>

      {/* <div className="w-full h-[150px]">
        <img className='w-full h-full' src={'/Images/SRD-Top4HomePage.jpg'} alt={''} />
      </div> */}


      {/* Parent Div */}
      <SectionBanner>
        <div className='absolute bottom-0 right-2 text-center' style={{ lineHeight: '1' }}>
          <div className='font-poppins text-lg md:text-3xl md:tracking-wider' style={{ lineHeight: '1' }}>
            <span className='hidden md:inline'>Shahid Sahu, FCMI, PhD, MBA</span>
            <span className='md:hidden leading-none'>Shahid Sahu <br /><span className='text-sm leading-none' >FCMI, PhD, MBA</span></span>
          </div>

          <div className='font-poppins text-xs md:text-sm text-gray-600'>
            <span className='hidden md:inline'>Management Consultant/ Project Manager Office Lead (PMO Lead)</span>
            <span className='md:hidden'>Mgmt. Consultant/ PMO Lead</span>
          </div>
          <div className='font-poppins text-xs md:text-sm text-gray-600'>
            <span className='hidden md:inline'>at public, private and non-profit organisations</span>
            <span className='md:hidden'>at various organisations</span>

          </div>
        </div>

      </SectionBanner>


      {/* {BgColors.LightBackgroundColors50.map((E, I) =>
          <div className={`w-96 p-4 m-2 rounded ${E} shadow-md`}> {E}</div>
        )} */}


      {/* <div className="w-full max-w-5xl mx-auto mt-2 overflow-hidden"> */}
      <div className="w-full mx-auto overflow-hidden">
        <ImageSlider />
      </div>


      {/* <div className='w-full grid grid-cols-1 md:grid-cols-2 mt-8 h-[350px] border border-red-600'>
        <div> <div className='text-3xl flex flex-col justify-center items-center'>Chadder Cheese</div></div>
        <div className='w-full h-full' > <img className='w-full h-full  '  src={'/assets/SRD/Items/Cheddarche240928014134883.png'} alt={''} /> </div>
      </div> */}


      {/* <AlternatingSections /> */}


      {/* <div className="relative inline-block text-center"> */}
      <section className='mt-12'>

        <div className="mb-10 pb-4 relative text-center ">
          <span className="font-poppins text-4xl">Our Immigration Services Our Immigration Services</span>
          {/* <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-1/10 h-[4px] bg-blue-500">hlllldfgdfgfdfdsfsdfdsf dfgdsfgd fg dgdfgdfsg dsgdfg dgd df </div> */}
          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[10px] h-[5px] bg-blue-500 rounded-md"></span> */}

          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[6px] h-[6px] bg-blue-500 rounded-md"></span> */}

          <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 flex items-center space-x-1">
            {/* Chunks */}
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[100px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
          </div>
        </div>

        <SectionServices />
      </section>

      <section className='mt-12'>
        <div className="mb-10 pb-4 relative text-center ">
          <span className="font-poppins text-4xl text-gray-800 ">Watch Our Introductory Video</span>
          {/* <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-1/10 h-[4px] bg-blue-500">hlllldfgdfgfdfdsfsdfdsf dfgdsfgd fg dgdfgdfsg dsgdfg dgd df </div> */}
          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[10px] h-[5px] bg-blue-500 rounded-md"></span> */}

          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[6px] h-[6px] bg-blue-500 rounded-md"></span> */}

          <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 flex items-center space-x-1">
            {/* Chunks */}
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[100px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
          </div>
        </div>

        <div className="mx-auto md:mx-20 p-2 md:p-4 border rounded-lg shadow-xl">
          <VideoSection />
        </div>
      </section>



      <section className='mt-12'>
        <div className="mb-10 pb-4 relative text-center ">
          <span className="font-poppins text-4xl">Benefits of Studying in United Kingdom</span>
          {/* <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-1/10 h-[4px] bg-blue-500">hlllldfgdfgfdfdsfsdfdsf dfgdsfgd fg dgdfgdfsg dsgdfg dgd df </div> */}
          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[10px] h-[5px] bg-blue-500 rounded-md"></span> */}

          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[6px] h-[6px] bg-blue-500 rounded-md"></span> */}

          <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 flex items-center space-x-1">
            {/* Chunks */}
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[100px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
          </div>
        </div>

        <SectionBenefits />
      </section>


      {/* <div className="text-center">
        <div
          className="mx-auto h-[4px] w-1/5 mt-2"
          style={{
            backgroundImage:
              "repeating-linear-gradient(to right, black 0, black 2px, transparent 2px, transparent 6px, black 6px, black 12px, transparent 12px, transparent 20px)",
            borderRadius: "2px",
          }}
        ></div>
      </div> */}


      <section className='mt-12'>

        <div className="mb-10 pb-4 relative text-center ">
          <span className="font-poppins text-4xl">Here We Are</span>
          {/* <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-1/10 h-[4px] bg-blue-500">hlllldfgdfgfdfdsfsdfdsf dfgdsfgd fg dgdfgdfsg dsgdfg dgd df </div> */}
          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[10px] h-[5px] bg-blue-500 rounded-md"></span> */}

          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[6px] h-[6px] bg-blue-500 rounded-md"></span> */}

          <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 flex items-center space-x-1">
            {/* Chunks */}
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[100px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
          </div>
        </div>

        <div className="container flex gap border rounded-lg shadow-xl p-4">
          <div></div>
          <div></div>

        </div>
      </section>

      {/* <section className="mt-4 md:mt-[50px]">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.026457474389!2d71.4338397104932!3d30.207792310709127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b33c97b7052c1%3A0xe599678cfdba5f37!2sWavelaz%20Diagnostic%20%26%20Refractive%20Surgery%20Centre!5e0!3m2!1sen!2s!4v1716588290651!5m2!1sen!2s"
          width={600} height={450} style={{ border: 0, width: '100%' }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
        />
      </section> */}

      {/* <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg"> */}
      <section className="mt-4 md:mt-[50px]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3478.674354540646!2d71.446694!3d30.2034456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393b33c498380c91%3A0x8c8c82ff0ac9a4a5!2sAhsan%20Medicine%20Company!5e0!3m2!1sen!2s!4v1696352147173!5m2!1sen!2s"
          // width="100%" height="100%" style={{ border: 0 }}
          width="100%" height={450} style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Ahsan Medicine"
        ></iframe>
      </section>

      <section className='mt-12'>
        <div className="mb-10 pb-4 relative text-center ">
          <span className="font-poppins text-4xl">Benefits of Studying in United Kingdom</span>
          {/* <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-1/10 h-[4px] bg-blue-500">hlllldfgdfgfdfdsfsdfdsf dfgdsfgd fg dgdfgdfsg dsgdfg dgd df </div> */}
          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[10px] h-[5px] bg-blue-500 rounded-md"></span> */}

          {/* <span className="absolute left-1/2 bottom-0 transform -translate-x-1/2 w-[6px] h-[6px] bg-blue-500 rounded-md"></span> */}

          <div className="absolute left-1/2 bottom-0 transform -translate-x-1/2 flex items-center space-x-1">
            {/* Chunks */}
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[100px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[12px] h-[6px] bg-red-600 rounded-md"></div>
            <div className="w-[6px] h-[6px] bg-red-600 rounded-md"></div>
          </div>
        </div>

        <SectionBenefits />
      </section>


      {/* <div>
        {images.map((image, index) => (
          <Parallax
            key={index}
            bgImage={image}
            strength={200 - index * 40} // Adjust strength for each image
            className="h-[500px]" // Adjust height as needed
          >
            <div className="h-full flex items-center justify-center text-white">
              <h1 className="text-2xl font-bold">
                Parallax Image {index + 1}
              </h1>
            </div>
          </Parallax>
        ))}
      </div> */}

    </main>
  )
}

