import React from 'react'
import { AiOutlineFacebook } from 'react-icons/ai'
import { BsTelephoneInbound } from 'react-icons/bs'
import { FaGlobe, FaInstagram, FaWhatsapp } from 'react-icons/fa6'
import { ImLocation } from 'react-icons/im'

export default function AddressTopBar() {
  return (
    // <div className='px-2 md:px-0 w-full text-sm bg-slate-300 flex flex-col md:flex-row md:justify-around '>
    <div className='px-2 md:px-0 w-full text-sm flex flex-col md:flex-row md:justify-around '>
      <div className="flex gap-2  items-center "><span className='bg-white rounded-full w-4 h-4 flex justify-center items-center '><ImLocation className='text-red-600' /></span>St Owen's Cross, England, United Kingdom </div>
      <div className="flex gap-2  items-center "> <span className='bg-white rounded-full w-4 h-4 flex justify-center items-center '><BsTelephoneInbound /></span>061-4517507, 4517507</div>
      <div className="flex gap-2  items-center "><span className='bg-white rounded-full w-4 h-4 flex justify-center items-center '><FaWhatsapp className='text-green-600' /></span>03344373507</div>
      {/* <div className="flex gap-2  items-center "><span  className='bg-white rounded-full w-4 h-4 flex justify-center items-center '><FaInstagram style={{ color: '#fcc201' }} /></span>SUNRISE </div> */}
      <div className="flex gap-2  items-center "><span className='bg-white rounded-full w-4 h-4 flex justify-center items-center '><FaInstagram className='text-orange-500' /></span>Sahu & Co </div>
      <div className="flex gap-2  items-center "><span className='bg-white rounded-full w-4 h-4 flex justify-center items-center '><AiOutlineFacebook className='text-blue-600' /></span>www.facebook.com/Sahu&Co </div>
      <div className="flex gap-2  items-center "><span className='bg-white rounded-full w-4 h-4 flex justify-center items-center pe-[0.5px]'><FaGlobe /></span>www.Sahu&Co.com </div>

    </div>
  )
}
