import React from 'react'
import { AiOutlineFacebook } from 'react-icons/ai'
import { BsTelephoneInbound } from 'react-icons/bs'
import { FaGlobe, FaInstagram, FaWhatsapp } from 'react-icons/fa6'
import { ImLocation } from 'react-icons/im'

export default function SectionBanner({children}) {
  return (
<div
        style={{
          '--image-url': `url(${'/assets/images/unionjackflagBanner.jpg'})`,
          backgroundImage: 'var(--image-url)',
          backgroundSize: '100% 100%', // Stretch the image to fit both width and height
          backgroundRepeat: 'no-repeat', // Prevents tiling

          // backgroundSize: 'cover', // Ensures the image covers the entire div
          // backgroundPosition: 'center' // Centers the image
        }}
        // className='w-full h-[150px] bg-[image:var(--image-url)] bg-cover bg-center bg-no-repeat'     
        className='w-full h-[150px] bg-[image:var(--image-url)] relative'
      >
        {/* Bottom-Right Content */}
        {/* <div className='absolute bottom-0 right-2 px-2' style={{ lineHeight: '1' }}>
          <div className='font-poppins text-3xl tracking-wider'>Shahid Sahu, FCMI, PhD, MBA</div>
          <div className='font-poppins text-sm text-gray-600'>
            Management Consultant/Project Manager Office Lead (PMO Lead)
          </div>
          <div className='font-poppins text-sm text-gray-600'>
            at public, private and non-profit organisations
          </div>
        </div> */}
        {children}
      </div>
    
  )
}
